.MeetingFollowUp {
  height: calc(100% - 46px);
  overflow: hidden;

  .ant-layout .ant-layout-sider {
    background: white !important;
  }

  // .ant-layout-sider-children {
  //   height: calc(100vh - 145px) !important;
  // }

  .ant-divider-horizontal {
    margin: 0px 0 !important;
  }

  .ant-collapse {
    border: none !important;
    background-color: none !important;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    // border: none !important;
    background-color: white !important;
    padding: 6px 16px !important;
  }

  .ant-collapse .ant-collapse-content {
    border-top: none !important;
  }

  .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0px 16px !important;
  }

  .builder {
    .ant-space {
      display: grid !important;
    }
  }

  .ant-input {
    min-height: 0;
    background: white !important;
  }

  .ant-select {
    min-height: 0;
    background: #f0f0f0 !important;
  }

  .ant-select-selector {
    border: 1px solid #d9d9d9;
    background: none !important;
  }

  .ant-picker-outlined {
    min-height: 0;
    background: white !important;
  }

  .ant-table-wrapper .ant-table-thead>tr>th,
  :where(.css-dev-only-do-not-override-usln0u).ant-table-wrapper .ant-table-thead>tr>td {
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: start;
    background: #EBEBEB !important;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.2s ease;
  }

  .ant-collapse .ant-collapse-icon-position-end,
  :where(css-dev-only-do-not-override-1xg9z9n) {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    background-color: rgba(0, 0, 0, 0.02);
    // border: 1px solid #d9d9d9; 
    border: none !important;
    border-bottom: 0;
    border-radius: 8px;
}

.ant-drawer-body {
  padding: 2px !important;
}

  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
    border-inline-end: none !important;
  }

  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
    border-inline-end: none !important;
  }

  .follow-container .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td {
    border-inline-end: none !important;
  }

  .follow-container .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th {
    border-inline-end: none !important;
  }

  .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th {
    border-inline-end: none !important;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    // border: none !important;
    // background-color: white !important;
    // padding: 10px 20px !important;
  }

.ant-drawer .ant-drawer-body {
    padding: 2px !important;
}
}

.MeetingFollowUp  .ant-layout-footer {
  background: white !important;
  padding: 0px;
}

.followup-filter-header{
  padding: 0px !important;
  // height: 90px !important;
}

.followup-filter-content{
  height: calc(100vh - 145px) !important;
  overflow-x: auto;
}

.follow-container .ant-table-wrapper table {
  height: 50px;
  padding: 10px;
  // table-layout: fixed !important;
}

.follow-container .ant-table-wrapper .ant-table-body table {
  height: 50px;
  // table-layout: inherit !important;
}

.follow-container-small .ant-table-wrapper table {
  height: 50px;
  table-layout: fixed !important;
}

.follow-container-small .ant-table-wrapper .ant-table-body table {
  height: 50px;
  table-layout: inherit !important;
}

.follow-container-small .width-action-small {
  min-width: 70px !important;
}

.follow-container-small .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td{
  border-inline-end: none !important;
}
.follow-container-small .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th {
  border-inline-end: none !important;
}

.ant-popover {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%) !important;
}