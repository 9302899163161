@import "../../../Shared/CSS/_variables";
.engagement-card{
    margin-top: 10px;
    // height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    .edit-meet-button{
      background: $btn-primary-bg !important;
    }
    .ant-card-body {
        padding: 10px !important;
      }
      .ant-switch-checked {
        background-color: #E0B41C !important;
     }
     .ant-table-container{
      border: 1px solid #f0f0f0 !important;
     }
     .ant-card .ant-card-head {
      padding: 0px 11px !important;
     }

      .eng-data{
        .ant-select-single {
            font-size: 14px;
            height: 40px;
        }
        .eng-form-root {
            color        : #6a6d70;
            font-size    : 14px;
            font-weight  : 600;
            margin-bottom: 8px;
            margin-left  : 1px;
        }
        span.ant-radio+* {
            font-weight: 500;
        }
      }
      .submit-block{
        display: flex;
        justify-content: end;
        padding-top: 20px;
      }
      .add-Meeting-button{
        display: flex;
        justify-content: end;
      }
      .ant-table-wrapper table {
        height: 85px;
    }
    .first-step{
      // overflow-x: auto;
      // min-height: calc(100vh - 300px);
      width: 100%;
      overflow: auto;
    }
    .meeting-header{
      margin-top: 10px;
      min-height: 60px;
      .meeting-date{
        font-size: 25px;
        display: flex;
        justify-content: center;
        font-weight: 500;
      }
      .meeting-month{
        font-size: 12px;
        display: grid;
        justify-content: center;
        font-weight: 700;
        height: -webkit-fill-available;
        align-items: center;
      }
      .end-align{
        display: flex;
        justify-content: end;
      }
      .meet-det-class{
        font-weight: 500;
        max-width: 250px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        /* border: 1px solid; */
        text-overflow: ellipsis;
      }
      .meet-det-class-lg{
        font-weight: 500;
        max-width: 380px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        /* border: 1px solid; */
        text-overflow: ellipsis;
      }
      .meet-det-class-high{
        font-weight: 500;
        max-width: 480px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        /* border: 1px solid; */
        text-overflow: ellipsis;
      }
      
    }
}

.profile-body{
    .ant-card-body {
        padding: 10px;
      }
      h4 {
        margin: 0px;
      }
      h5{
        margin-top: 0px !important;
        margin: 0px;
      }
}
nz-select {
    margin: 0 8px 10px 0;
    width: 120px;
  }

  .ant-timeline-item {
    font-weight: 500;
}

.just{
display: flex;
justify-content: space-between;
}
.custom-box-shadow{
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.home-tabs {
  padding    : 0px 0%;
  display    : flex;
  margin : 10px 0;
  text-align : center !important;
}
.tabs-list {
  background-color: #fff;
  border          : 1px solid #E1E1E1;
  width           : fit-content;

  padding            : 6px 2%;
  // margin          : 0px 10px;
  color              : #ffffff;
  border-radius      : 2px;
  cursor             : pointer;
  align-items: center;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: #e6e6e6;;

    span {
      color      : #000000;
      // border-left: 1px solid #000000;
    }
  }

  &.active {
    background-color: #000000;
    color           : #fff;
    font-weight     : 400;

    span {
      color: #fff;
    }
  }

  span {
    color: #000000;
  }
}

.class-50{
  width: 50% !important;
}

.width-100 {
  width: 100%;
}

.edit-meeting-icon:hover
{
  // transform: scale(1.3);
}
.chat-edit-option:hover{
  background-color: #B8BCBF;
  border-radius: 3px;
}

.height-85{
  height: 90px;
}

.height-auto{
  height: auto;
}
